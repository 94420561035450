import { Empresa } from '@/models/empresa';
import { PAGE_VISIT } from '@/models/events';

const VERSION = 'version';

export const url = (uri: string) => {
    return `${window.apiHost}${uri.startsWith('/') ? uri : `/${uri}`}`;
};

export const keepAlive = async () => {
    setInterval(
        async () => {
            try {
                const r = await fetch(url('/isiWeb/web/v1/keep-alive'), {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                console.log('keepAlive@response', r);

                if (!r.ok) {
                    throw new Error(`Keep-Alive response status not OK: ${r.status}`);
                }
            } catch (error: any) {
                console.error('Failed to keep alive', error);
            }
        },
        5 * 60 * 1000, // Run every 5 minutes
    );
};

/**
 * @throws Error if the Company is not found
 */
export const getCompanyGlobalData = async (companyId: string): Promise<Empresa> => {
    try {
        const cacheKey = `_companyGlobalData_${companyId}`;
        const cached = window.cache.get(cacheKey);
        if (cached) {
            console.log('getCompanyGlobalData@cache', cached);
            return cached as Empresa;
        }
        console.log('getCompanyGlobalData@fetch');

        const r = await fetch(url(`/isiWeb/web/v1/empresas/${companyId}/global-data`), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!r.ok) {
            throw new Error(`Company ${companyId} global data response status not OK: ${r.status}`);
        }

        const response = await r.json();
        const empresa = response.data as Empresa;

        if (!empresa) {
            const error = new Error(`Company ${companyId} global data could not be retrieved`);
            console.error(error.message, response);
            throw error;
        }

        window.cache.set(cacheKey, empresa);
        console.log('getCompanyGlobalData@cache set', empresa);

        return empresa;
    } catch (error: any) {
        console.error('Failed to get company global data', error);
        throw error;
    }
};

export const handleVersion = async () => {
    const currentVersion = await getVersion();
    if (!sessionStorage.getItem(VERSION)) {
        sessionStorage.setItem(VERSION, currentVersion);
    }

    console.log('Current version', currentVersion);

    document.addEventListener(PAGE_VISIT, async () => {
        const currentVersion = await getVersion();
        console.log('Page visited', window.PineconeRouter.context.path);
        if (sessionStorage.getItem(VERSION) !== currentVersion) {
            console.log('Version mismatch, reloading');
            sessionStorage.setItem(VERSION, currentVersion);

            /**
             * sessionStorage handles values instantly in memory but there is a delay until they are persisted.
             * We cannot use "fake" redirection using pinecone router to avoid a page reload, which causes
             * a race condition where the sessionStorage is not available after a page reload.
             * Therefore, we use window.location.href with a setTimeout to avoid the race condition.
             * We use 300ms to give the browser time to persist the sessionStorage modifications.
             */
            setTimeout(() => {
                console.log('Redirecting timeout');
                window.location.href = window.PineconeRouter.context.path;
            }, 300);
        }
    });
};

const getVersion = async (): Promise<string> => {
    try {
        const r = await fetch('/version.json', {
            method: 'GET',
        });

        if (!r.ok) {
            throw new Error(`Version response status not OK: ${r.status}`);
        }

        return await r.json();
    } catch (error: any) {
        console.error('Failed to get version', error);
        return '';
    }
};

export const getApiHost = (): string => {
    let host = 'https://dev-api.isiparts.com:8443';

    const locationRegex = window.location.hostname.match(/^(.*?)\.isiparts\.com$/i);

    if (locationRegex !== null && locationRegex.length > 1) {
        host = `https://${locationRegex[1]}-api.isiparts.com:8443`;
    }

    return host;
};
