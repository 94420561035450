import { defaultComponent } from '@/main';
import { PAGE_VISIT, TOAST_TRIGGER } from '@/models/events';
import { ToastError } from '@/models/toast';
import { User } from '@/models/user';
import posthog from 'posthog-js';
import { url } from './api.service';

/**
 * Use keep-alive endpoint reponse status to check if the user is authenticated
 */
export const isAuthenticated = async (): Promise<boolean> => {
    const r = await fetch(url('/isiWeb/web/v1/keep-alive'), {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

    return r.ok;
};

export const authenticationMiddleware = async () => {
    console.log('auth@handleAuthenticationRoutes init');

    await handleAuthenticationRedirections();

    document.addEventListener(PAGE_VISIT, async () => {
        console.log('auth@handleAuthenticationRoutes page visit', window.PineconeRouter.context);
        await handleAuthenticationRedirections();
    });
};

const handleAuthenticationRedirections = async () => {
    const loggedIn = await isAuthenticated();
    console.log('auth@handleAuthenticationRoutes loggedIn', loggedIn);

    if (!loggedIn && window.PineconeRouter.context.route !== '/login') {
        location.href = '/login';
    }

    if (loggedIn && window.PineconeRouter.context.route === '/login') {
        location.href = '/';
    }

    defaultComponent().loading = window.PineconeRouter.context.route === '/login' ? false : !loggedIn;
};

/**
 * @throws Error if the user is not authenticated
 */
export const getAuthenticatedUser = async (): Promise<User> => {
    try {
        const cacheKey = '_globalData';
        const cached = window.cache.get(cacheKey);

        if (cached) {
            console.log('getAuthenticatedUser@cache', cached);
            return cached as User;
        }

        console.log('getAuthenticatedUser@fetch');

        const r = await fetch(url('/isiWeb/web/v1/global-data'), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        console.log('getAuthenticatedUser@response', r);

        if (!r.ok) {
            throw new Error(`Global data response status not OK: ${r.status}`);
        }

        const response = await r.json();

        console.log('getAuthenticatedUser@response JSON', response);

        const data = response.data;

        if (!data) {
            const error = new Error('Global data could not be retrieved');
            console.error(error.message, response);
            throw error;
        }

        window.cache.set(cacheKey, data);
        console.log('getAuthenticatedUser@cache set', data);

        return data as User;
    } catch (error: any) {
        console.error('Failed to get global data', error);
        throw error;
    }
};

export const authenticate = async (
    username: string,
    password: string,
): Promise<{
    success: boolean;
    user: User | null;
    message: string;
}> => {
    console.log('auth@authenticate');

    window.cache.clear();

    try {
        const body = new URLSearchParams();
        body.set('j_username', username);
        body.set('j_password', password);

        const r = await fetch(url('/isiWeb/static/auth/j_spring_security_check'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body,
            credentials: 'include',
        });

        if (!r.ok) {
            throw new Error(r.statusText);
        }

        setTimeout(() => {
            console.log('auth@authenticate ensure cookie is set');
        }, 300);

        // JSESSIONID is now set

        const user = await getAuthenticatedUser();
        console.log('authenticate@user', user);

        if (user) {
            try {
                posthog.identify(user.usuario, user);
            } catch (error) {
                console.warn('Posthog identify failed', error);
            }
        }

        return {
            success: user !== null,
            user,
            message: r.statusText,
        };
    } catch (error) {
        console.warn('auth@authenticate error', error);

        window.dispatchEvent(
            new CustomEvent(TOAST_TRIGGER, {
                detail: {
                    msg: error,
                    level: ToastError,
                },
            }),
        );

        return {
            success: false,
            user: null,
            message: error as string,
        };
    }
};

export const logout = async (): Promise<void> => {
    console.log('auth@logout');

    const r = await fetch(url('/isiWeb/static/auth/j_spring_security_logout'), {
        method: 'POST',
        credentials: 'include',
    });

    if (r.status !== 200) {
        throw new Error('Logout failed');
    }

    setTimeout(() => {
        console.log('auth@authenticate ensure cookie is unset');
    }, 300);

    // JSESSIONID is now unset

    try {
        posthog.reset();
    } catch (error) {
        console.warn('Posthog reset failed', error);
    }

    localStorage.removeItem('_version');
    window.cache.clear();
};
